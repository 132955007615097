enum GatheringRateModifierId {
    WHEELBARROW = 'WHEELBARROW',
    DOUBLE_BROADAX = 'DOUBLE_BROADAX',
    LUMBER_PRESERVATION = 'LUMBER_PRESERVATION',
    CROSSCUT_SAW = 'CROSSCUT_SAW',
    HORTICULTURE = 'HORTICULTURE',
    FERTILIZATION = 'FERTILIZATION',
    CROSS_BREEDING = 'CROSS_BREEDING',
    SPECIALIZED_PICK = 'SPECIALIZED_PICK',
    ACID_DISTILLATION = 'ACID_DISTILLATION',
    IMPROVED_DOUBLE_BROADAX = 'IMPROVED_DOUBLE_BROADAX',
    IMPROVED_LUMBER_PRESERVATION = 'IMPROVED_LUMBER_PRESERVATION',
    IMPROVED_CROSSCUT_SAW = 'IMPROVED_CROSSCUT_SAW',
    IMPROVED_HORTICULTURE = 'IMPROVED_HORTICULTURE',
    IMPROVED_FERTILIZATION = 'IMPROVED_FERTILIZATION',
    IMPROVED_CROSS_BREEDING = 'IMPROVED_CROSS_BREEDING',
    IMPROVED_SPECIALIZED_PICK = 'IMPROVED_SPECIALIZED_PICK',
    IMPROVED_ACID_DISTILLATION = 'IMPROVED_ACID_DISTILLATION',
    FORESTRY = 'FORESTRY',
    ENGLISH_DARK_AGE = 'ENGLISH_DARK_AGE',
    ENGLISH_FEUDAL_AGE = 'ENGLISH_FEUDAL_AGE',
    ENGLISH_CASTLE_AGE = 'ENGLISH_CASTLE_AGE',
    ENGLISH_IMPERIAL_AGE = 'ENGLISH_IMPERIAL_AGE',
    GRANARY = 'GRANARY',
    BOUNTY_100 = 'BOUNTY_100',
    BOUNTY_250 = 'BOUNTY_250',
    BOUNTY_500 = 'BOUNTY_500',
    MUSLIM_BERRIES = 'MUSLIM_BERRIES',
    GOLDEN_AGE_1 = 'GOLDEN_AGE_1',
    GOLDEN_AGE_2 = 'GOLDEN_AGE_2',
    GOLDEN_AGE_3 = 'GOLDEN_AGE_3',
    AGRICULTURE = 'AGRICULTURE',
    ANATOLIAN_HILLS = 'ANATOLIAN_HILLS',
    DRAGON_VILLAGER = 'DRAGON_VILLAGER',
    AYYUBIDS_GOLDEN_AGE_1 = 'AYYUBIDS_GOLDEN_AGE_1',
    DAIMYO_MANOR = 'DAIMYO_MANOR',
    DAIMYO_PALACE = 'DAIMYO_PALACE',
    SHOGUNATE_CASTLE = 'SHOGUNATE_CASTLE',
    CISTERN_LEVEL_1 = 'CISTERN_LEVEL_1',
    CISTERN_LEVEL_2 = 'CISTERN_LEVEL_2',
    CISTERN_LEVEL_3 = 'CISTERN_LEVEL_3',
    CISTERN_LEVEL_4 = 'CISTERN_LEVEL_4',
    CISTERN_LEVEL_5 = 'CISTERN_LEVEL_5'
}

export const DOUBLE_BROADAX_MULTIPLIER = 1.1;
export const LUMBER_PRESERVATION_MULTIPLIER = 1.09;
export const CROSSCUT_SAW_MULTIPLIER = 1.08;
export const HORTICULTURE_MULTIPLIER = 1.08;
export const FERTILIZATION_MULTIPLIER = 1.067;
export const CROSS_BREEDING_MULTIPLIER = 1.067;
export const SPECIALIZED_PICK_MULTIPLIER = 1.12;
export const ACID_DISTILLATION_MULTIPLIER = 1.11;
export const CUPELLATION_MULTIPLIER = 1.1;

export default GatheringRateModifierId;
